<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">集团名称:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_jitu_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col> 
				<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加集团
					</el-button>
				</el-col>
			</el-row> 
			<br />

 
			<el-table :data="m_jituan_list" style="width: 100%">
				<el-table-column prop="jitu_id" label="集团id">
				</el-table-column>
				<el-table-column prop="jitu_name" label="集团名称">
				</el-table-column> 
				<el-table-column prop="jitu_label_1" label="标签1">
				</el-table-column>
				<el-table-column prop="jitu_label_2" label="标签2">
				</el-table-column>
				<el-table-column prop="jitu_chanliang" label="产量">
				</el-table-column>
				<el-table-column label="操作" show-overflow-tooltip align="right">
					<template slot-scope="scope">
						 <el-button size="mini" type="danger" @click="f_jituan_set(scope.row.jitu_id )">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<br />
			<br />
			
			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="添加集团" :visible.sync="drawer_1" size="600px">
			<jituan_add :parent="me"></jituan_add>
		</el-drawer>

		<el-drawer title="修改集团" :visible.sync="drawer_2" size="600px">
			<jituan_set :key="m_jitu_id" :m_jitu_id="m_jitu_id" :parent="me"></jituan_set>
		</el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import jituan_add from './components/jituan_add/jituan_add'
	import jituan_set from './components/jituan_set/jituan_set'
	import jituan_get from "./expand/jituan_get.js"


	export default {
		components: {
			layout,
			jituan_add,
			jituan_set
		},
		data() {
			return {
				me: this,
				m_jitu_name: "",
				m_jituan_list: [],
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_jitu_id : 0,
				m_jitu_shan_id : ""
			}
		},
		created() {
			const that = this;
			if(this.$route.query.shangjia_id >0){
				that.m_jitu_shan_id = this.$route.query.shangjia_id;
			}
			  
			that.jituan_get = new jituan_get(that);
			that.jituan_get.m_main(); 
			 
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}, 
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.jituan_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				
				that.jituan_get.m_main();
			},
			f_jituan_set(jitu_id) {
				const that = this;
				
				that.m_jitu_id = jitu_id;
				that.drawer_2 = true; 
			}
		}
	}
</script>
