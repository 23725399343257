import request from "tools/request.js"

//建筑分类 添加建筑分类
class jituan_set_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		   
		let session 					= localStorage.getItem("session"); 
 
 
		let params = { 
			m_jituan_info : that.parent.m_jituan_info, 
			m_img_1_2 : that.parent.m_img_1_2,  
			m_img_2_2 : that.parent.m_img_2_2,     
			m_img_3_2 : that.parent.m_img_3_2,      
			m_img_4_2 : that.parent.m_img_4_2,      
			m_img_5_2 : that.parent.m_img_5_2,   
			session : session
		} 
		 
		request.post('/jituan/jituan_set_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.jituan_get.m_main(); 
		})
		 
	}
}

export default jituan_set_post