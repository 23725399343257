<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />
			
			 
		 
			 
			 
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					集团名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="jitu_name"></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					集团logo:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess_1" :before-upload="beforeAvatarUpload">
						<img v-if="m_img_1_1" :src="m_img_1_1" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<button v-if="m_img_1_1" @click="quxiao_img_1">x</button>
				</el-col>
			</el-row> 
			  
			  <br />
			 <el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		集团产品:
			 	</el-col>
			 	<el-col :span="16">
			 		<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
			 			:on-success="handleAvatarSuccess_2" :before-upload="beforeAvatarUpload">
			 			<img v-if="m_img_2_1" :src="m_img_2_1" class="avatar">
			 			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			 		</el-upload>
					<button v-if="m_img_2_1" @click="quxiao_img_2">x</button>
			 	</el-col>
			 </el-row>
			 <br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					标签1:
				</el-col>
				<el-col :span="16">
					<el-input v-model="jitu_label_1"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					标签2 :
				</el-col>
				<el-col :span="16">
					<el-input v-model="jitu_label_2"></el-input>
				</el-col>
			</el-row>
			<br />


			<el-row>
				<el-col class="yu_input_label" :span="8">
					产量:
				</el-col>
				<el-col :span="16">
					<el-input v-model="jitu_chanliang"></el-input>
				</el-col>
			</el-row>
			<br />
			 <el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		详情图片1:
			 	</el-col>
			 	<el-col :span="16">
			 		<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
			 			:on-success="handleAvatarSuccess_3" :before-upload="beforeAvatarUpload">
			 			<img v-if="m_img_3_1" :src="m_img_3_1" class="avatar">
			 			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			 		</el-upload>
					<button v-if="m_img_3_1" @click="quxiao_img_3">x</button>
			 	</el-col>
			 </el-row>
			 <br /> 
			 <el-row>
			 	<el-col class="yu_input_label" :span="8">
			 		详情图片2:
			 	</el-col>
			 	<el-col :span="16">
			 		<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
			 			:on-success="handleAvatarSuccess_4" :before-upload="beforeAvatarUpload">
			 			<img v-if="m_img_4_1" :src="m_img_4_1" class="avatar">
			 			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			 		</el-upload> 
					<button v-if="m_img_4_1" @click="quxiao_img_4">x</button>
			 	</el-col>
			 </el-row>
			 <br /> 
			  <el-row>
			  	<el-col class="yu_input_label" :span="8">
			  		详情图片3:
			  	</el-col>
			  	<el-col :span="16">
			  		<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
			  			:on-success="handleAvatarSuccess_5" :before-upload="beforeAvatarUpload">
			  			<img v-if="m_img_5_1" :src="m_img_5_1" class="avatar">
			  			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  		</el-upload>
					<button v-if="m_img_5_1" @click="quxiao_img_5">x</button>
			  	</el-col>
			  </el-row>
			  <br />
			<el-row class="yu_content_right">
				<el-button @click="jituan_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import jituan_add_post from "./expand/jituan_add_post.js";

	export default {

		props: ["parent"],
		data() {
			return { 
				jitu_name: "", 
				jitu_label_1: "",
				jitu_label_2: "",
				jitu_chanliang: "", 
				m_img_1_1: '',
				m_img_1_2: '',
				m_img_2_1: '',
				m_img_2_2: '', 
				m_img_3_1: '',
				m_img_3_2: '', 
				m_img_4_1: '',
				m_img_4_2: '', 
				m_img_5_1: '',
				m_img_5_2: '', 
				m_shpi_beizhu: ""
			}
		},
		created() {
			const that = this;
			// that.shoppe_add_info_get = new shoppe_add_info_get(that);
			// that.shoppe_add_info_get.m_main();
		},
		methods: {
			jituan_add() {
				const that = this;
				that.jituan_add_post = new jituan_add_post(that);
				that.jituan_add_post.m_main();
			},
			handleAvatarSuccess_1(res, file) {
				const that = this;  
				that.m_img_1_2 = res.img_val;
				this.m_img_1_1 = URL.createObjectURL(file.raw);
			},
			quxiao_img_1() {
				const that = this;
				that.m_img_1_1 = ""; 
				that.m_img_1_2 = "";
			},
			handleAvatarSuccess_2(res, file) {
				const that = this;  
				that.m_img_2_2 = res.img_val;
				this.m_img_2_1 = URL.createObjectURL(file.raw);
			}, 
			quxiao_img_2() {
				const that = this;
				that.m_img_2_1 = ""; 
				that.m_img_2_2 = "";
			},
			handleAvatarSuccess_3(res, file) {
				const that = this;  
				that.m_img_3_2 = res.img_val;
				this.m_img_3_1 = URL.createObjectURL(file.raw);
			}, 
			quxiao_img_3() {
				const that = this;
				that.m_img_3_1 = ""; 
				that.m_img_3_2 = "";
			},
			handleAvatarSuccess_4(res, file) {
				const that = this;  
				that.m_img_4_2 = res.img_val;
				this.m_img_4_1 = URL.createObjectURL(file.raw);
			}, 
			quxiao_img_4() {
				const that = this;
				that.m_img_4_1 = ""; 
				that.m_img_4_2 = "";
			},
			handleAvatarSuccess_5(res, file) {
				const that = this;  
				that.m_img_5_2 = res.img_val;
				this.m_img_5_1 = URL.createObjectURL(file.raw);
			}, 
			quxiao_img_5() {
				const that = this;
				that.m_img_5_1 = ""; 
				that.m_img_5_2 = "";
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 png 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
