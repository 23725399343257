import request from "tools/request.js"

//商品设置 获取
class shangpin_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_jitu_id 		 	 = that.parent.m_jitu_id;
		  let session = localStorage.getItem("session");
		 let params = {
		 	m_jitu_id : m_jitu_id,
			session : session
		 } 
		 
		request.post('/jituan/jituan_set_get',params).then(res => { 
			  
			   
			that.parent.m_jituan_info = res.data.m_jituan_info; 
			that.parent.m_img_1_1 = that.parent.m_jituan_info["jitu_logo"];
			that.parent.m_img_2_1 = that.parent.m_jituan_info["jitu_chanpin"];
			that.parent.m_img_3_1 = that.parent.m_jituan_info["jitu_image_1"];
			that.parent.m_img_4_1 = that.parent.m_jituan_info["jitu_image_2"];
			that.parent.m_img_5_1 = that.parent.m_jituan_info["jitu_image_3"]; 
			
			that.parent.m_img_1_2 = that.parent.m_jituan_info["jitu_logo2"];
			that.parent.m_img_2_2 = that.parent.m_jituan_info["jitu_chanpin2"];
			that.parent.m_img_3_2 = that.parent.m_jituan_info["jitu_image_12"];
			that.parent.m_img_4_2 = that.parent.m_jituan_info["jitu_image_22"];
			that.parent.m_img_5_2 = that.parent.m_jituan_info["jitu_image_32"]; 
		})
		 
	}
}

export default shangpin_set_get