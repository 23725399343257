import request from "tools/request.js"

//建筑分类 添加建筑分类
class jituan_add_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		   
		let session 					= localStorage.getItem("session"); 
 
 
		let params = { 
			jitu_name : that.parent.jitu_name,
			jitu_label_1 : that.parent.jitu_label_1,
			jitu_label_2 : that.parent.jitu_label_2,
			jitu_chanliang : that.parent.jitu_chanliang, 
			m_img_1_2 : that.parent.m_img_1_2,  
			m_img_2_2 : that.parent.m_img_2_2,     
			m_img_3_2 : that.parent.m_img_3_2,      
			m_img_4_2 : that.parent.m_img_4_2,      
			m_img_5_2 : that.parent.m_img_5_2,   
			session : session
		} 
		
		request.post('/jituan/jituan_add_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.jituan_get.m_main(); 
		})
		 
	}
}

export default jituan_add_post